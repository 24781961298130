import $ from 'jquery';
import Structure from '../modules/structure';

$.fn.mobileSubMenuSript = function() {

    const jqAllAffectedContainers = this;

    jqAllAffectedContainers.each(function() {
        const container = $(this);
        const structure = new Structure('mobileSubMenuSript', container, {
            mainClose: '.close-action .mobile-menu-close',
            subMenuClose: '.close-action .sub-menu-close',
            subMenuParent: {
                selector: ".has-sub-menu",
                many: true
            }
        }).structure;
        const subMenuesStructArray = [];
        structure.subMenuParent.each(function() {
            const subMenuContainer = $(this);
            const sunMenuStructure = new Structure('mobileSubMenuSript', subMenuContainer, {
                link: "a.parent",
                subMenu: ".sub-menu"
            }).structure;
            subMenuesStructArray.push(sunMenuStructure);
        });
        $(subMenuesStructArray).each(function() {
            const subMenuStruct = this;
            subMenuStruct.link.on('click', function(e) {
                e.preventDefault();
                subMenuStruct.subMenu.addClass('open');
                structure.mainClose.addClass('inactive');
                structure.subMenuClose.removeClass('inactive');
            });
        });
        structure.subMenuClose.on('click', function(e) {
            e.preventDefault();
            $(subMenuesStructArray).each(function() {
                const subMenuStruct = this;
                subMenuStruct.subMenu.removeClass('open');
            });
            structure.subMenuClose.addClass('inactive');
            structure.mainClose.removeClass('inactive');
        });
    });

    return jqAllAffectedContainers;
};

document.addEventListener('DOMContentLoaded', function() {
    $('.mobile-sub-menu-js').mobileSubMenuSript();
});
