import $ from 'jquery';
import Structure from '../modules/structure';

$.fn.collapsabaleTextXSScript = function() {

    const jqAllAffectedContainers = this;

    jqAllAffectedContainers.each(function() {
        const container = $(this);
        const structure = new Structure('collapsabaleTextScript', container, {
            openLink: '.callapsabale-action a',
            textContainer: '.callapsabale-text-zone'
        }).structure;
        structure.openLink.on('click', function(e) {
            e.preventDefault();
            if (container.hasClass('open')) {
                container.removeClass('open');
            } else {
                container.addClass('open');
            }
        });
    });

    return jqAllAffectedContainers;
};

document.addEventListener('DOMContentLoaded', function() {
    $('.xs-callapsabale-js').collapsabaleTextXSScript();
});
