import $ from 'jquery';

$.fn.customFAQ = function() {

    var jqAllAffectedContainers = this;

    jqAllAffectedContainers.each(function() {
        const jqContaienr = $(this);
        const questionBlock = jqContaienr.find('.question>a')[0];
        if (!questionBlock) {
            console.error('customFAQ: questionBlock by query ".question>a" not foudn in ', this);
            return false;
        }
        const answerBlock = jqContaienr.find('.answer')[0];
        if (!answerBlock) {
            console.error('customFAQ: answerBlock by query ".answer" not foudn in ', this);
            return false;
        }
        const answerInnerBlock = jqContaienr.find('.inner-answer')[0];
        if (!answerInnerBlock) {
            console.error('customFAQ: answerInnerBlock by query ".inner-answer" not found in ', this);
            return false;
        }
        if (jqContaienr.hasClass('open')) {
            const innerAnswerHeight = $(answerInnerBlock).innerHeight();
            $(answerBlock).height(innerAnswerHeight);
        } else {
            $(answerBlock).height(0);
        }
        $(questionBlock).on('click', function(e) {
            e.preventDefault();
            if (jqContaienr.hasClass('open')) {
                $(answerBlock).height(0);
                jqContaienr.removeClass('open');
            } else {
                const innerAnswerHeight = $(answerInnerBlock).innerHeight();
                $(answerBlock).height(innerAnswerHeight);
                jqContaienr.addClass('open');
            }
        })
    });
};

// changed from om dom loading becouse web fonts take more time to be applied
document.fonts.onloadingdone = function (fontFaceSetEvent) {
    $('.custom-faq .faq-block').customFAQ();
};
