import baseStyles from '../sass/base.scss';
import $ from 'jquery';
import 'slick-carousel';
import 'selectric';
import "selectric/public/selectric.css";
import anime from 'animejs/lib/anime.es.js';
import mobileMenuJS from './includes/mobile_menu.js';
import customFancyboxJS from './includes/custom_fancybox.js';
import customScrollsJS from './includes/custom_scrolls.js';
import commonSliderJS from './includes/common_slider.js';
import customTabsJS from './includes/custom_tabs.js';
import ratingFieldJS from './includes/rating_field.js';
import partnersSliderJS from './includes/partners_slider.js';
import collapsabaleTextJS from './includes/collapsabale_text.js';
import customFAQJS from './includes/custom_faq.js';
import reverseMobileSliderJS from './includes/reverse_mobile_slider.js';
import mobileSubMenuJS from './includes/mobile_sub_menu.js';
import collapsabaleTextXsJS from './includes/collapsabale_text_xs.js';

$(document).ready(function() {
    $('.selectric-select').selectric({
        maxHeight: 360,
        disableOnMobile: false,
        nativeOnMobile: true
    });
});

anime({
    targets: 'div.anime-hello-world',
    translateX: 250,
    rotate: '1turn',
    backgroundColor: 'blue',
    duration: 800,
    direction: 'alternate',
    loop: true,
    easing: 'easeInOutSine'
});

