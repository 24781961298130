import $ from 'jquery';

$('.partners-slider-holder').slick({
    slidesToShow: 1,
    slidesToScroll: 3,
    dots: false,
    arrows: false,
    infinite: true,
    autoplay: true,
    autoplaySpeed: 3000,
    variableWidth: true,
    speed: 500,
    cssEase: 'linear',
    rows: 0,
    responsive: [
        {
            breakpoint: 1280,
            settings: {
                slidesToScroll: 2,
            }
        },
        {
            breakpoint: 767,
            settings: {
                slidesToScroll: 1,
            }
        },
    ]
});