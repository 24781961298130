import $ from 'jquery';
import Structure from '../modules/structure';

$.fn.collapsabaleTextScript = function() {

    const jqAllAffectedContainers = this;

    jqAllAffectedContainers.each(function() {
        const container = $(this);
        const structure = new Structure('collapsabaleTextScript', container, {
            openLink: '.action a',
            textContainer: '.text-container',
            innerText: '.inner-text'
        }).structure;
        const innerHeight = structure.innerText.height();
        const baseHeight = structure.textContainer.height();
        structure.openLink.on('click', function(e) {
            e.preventDefault();
            if (container.hasClass('open')) {
                container.removeClass('open');
                structure.textContainer.height(baseHeight);
            } else {
                container.addClass('open');
                structure.textContainer.height(innerHeight);
            }
        });
    });

    return jqAllAffectedContainers;
};

document.addEventListener('DOMContentLoaded', function() {
    $('.collapsabale-js').collapsabaleTextScript();
});
